import React from "react";
import Game from 'src/games/hangman';
import Layout from 'src/components/layout';


export default function GamePage() {
    return <Layout>
        <h1>Hangman</h1>
        <Game/>
    </Layout>;
}
