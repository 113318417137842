import * as React from "react";
import {useState, useEffect} from "react";
import {Row, Col, Button, Alert} from 'reactstrap';
import classNames from "classnames";
import styles from './hangman.module.scss';
import WORDLIST from './wordlist';


export default function HangmanGamePage() {
    const [word, setWord] = useState("");

    const startGame = ()=> {
        setWord(pickRandom(WORDLIST));
    };

    useEffect(()=> {
        startGame();
    }, []);

    return <HangmanGame
               key={word}
               word={word}
               startGame={startGame} />;
}

const MAX_WRONG_GUESSES = 6;
const ALL_LETTERS = Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ");

function HangmanGame({word, startGame}) {
    const [revealed, setRevealed] = useState(new Set());
    const [errors, setErrors] = useState(0);

    const HAS_WON = hasGuessedAllLetters(word, revealed);
    const HAS_LOST = errors >= MAX_WRONG_GUESSES;
    const FINISHED = HAS_WON || HAS_LOST;

    const guessLetter = letter => {
        if (FINISHED) {
            return;
        }

        if (revealed.has(letter)) {
            return;
        }

        setRevealed(new Set([...revealed, letter]));

        if (!word.includes(letter)) {
            setErrors(errors + 1);
        }
    };

    return <Row>
        <Col xs="12" lg="8" className="order-2 order-lg-1 d-flex flex-column align-items-center">

            <div className="mb-3">
                <WordDisplay word={word} revealed={revealed} />
            </div>

            {FINISHED ||
             <Keyboard onClick={guessLetter} revealed={revealed} />}

            <KeyboardInput onLetterKeyDown={guessLetter} />

        </Col>
        <Col xs="12" lg="4" className="order-1 order-lg-2">

            {(()=> {

                if (HAS_LOST) {
                    return <Alert color="danger">
                        You lost! Word was {word}.
                        <div className="mt-3 text-center">
                            <Button color="danger"
                                    onClick={startGame}>
                                Try again
                            </Button>
                        </div>
                    </Alert>;
                }

                if (HAS_WON) {
                    return <Alert color="success">
                        <strong>Congrats!</strong>{' '}
                        You guessed the correct word.
                        <div className="mt-3 text-center">
                            <Button color="success"
                                    onClick={startGame}>
                                Play again
                            </Button>
                        </div>
                    </Alert>;
                }

                if (errors <= 0) {
                    return null;
                }

                return <Alert color="warning">
                    You guessed {errors} wrong.
                    You have {MAX_WRONG_GUESSES - errors} attempts left.
                </Alert>;

            })()}

            {false && <div className="mb-3 text-center">
                <Button color={FINISHED ? "primary" : "light"}
                        onClick={startGame}>
                    Start new game
                </Button>
            </div>}

        </Col>
    </Row>;
}


function hasGuessedAllLetters(word, revealed) {
    for (const letter of word) {
        if (!revealed.has(letter)) {
            return false;
        }
    }
    return true;
}


function WordDisplay({word, revealed}) {
    const letters = Array.from(word);

    return <div className="d-flex flex-wrap">
        {letters.map((letter, idx) =>
            <span key={idx} className={styles.letterCell}>
                {revealed.has(letter) ? letter : ''}
            </span>)}
    </div>;
}


function pickRandom(array) {
    return array[Math.floor(Math.random() * array.length)];
}


function Keyboard({revealed, onClick}) {
    const Component = KEYBOARDS.qwerty;
    return <Component revealed={revealed} onClick={onClick} />;
}


function KeyboardSimple({revealed, onClick}) {
    const letters = ALL_LETTERS.map(letter => ({
        letter,
        isRevealed: revealed.has(letter),
    }));
    return <div>
        {letters
            .filter(({isRevealed}) => !isRevealed)
            .map(({letter}) =>
                <Button key={letter} color="primary"
                        className="m-1"
                        onClick={() => onClick(letter)}>
                    {letter}
                </Button>)}
    </div>;
}


function KeyboardSimple2({revealed, onClick}) {
    const letters = ALL_LETTERS.map(letter => ({
        letter,
        isRevealed: revealed.has(letter),
    }));
    return <div>
        {letters
            .map(({letter, isRevealed}) =>
                <Button key={letter} color="primary"
                        disabled={isRevealed}
                        className="m-1"
                        onClick={() => onClick(letter)}>
                    {letter}
                </Button>)}
    </div>;
}


function KeyboardQwerty({revealed, onClick}) {

    const rows = [
        {letters: Array.from("QWERTYUIOP")},
        {letters: Array.from("ASDFGHJKL"),
         className: "pl-md-3"},
        {letters: Array.from("ZXCVBNM"),
         className: "pl-md-5"},
    ];

    return <div>
        {rows.map(({className, letters}, idx) =>
            <div className={classNames(className, "d-inline d-md-block")}
                 key={`row-${idx}`}>

                {letters.map(letter =>
                    <Button key={letter}
                            color="primary"
                            disabled={revealed.has(letter)}
                            style={{width: '3em', height: '3em'}}
                            className="m-1"
                            onClick={() => onClick(letter)}>
                        {letter}
                    </Button>)}
            </div>)}
    </div>;
}


const KEYBOARDS = {
    simple: KeyboardSimple,
    simple2: KeyboardSimple2,
    qwerty: KeyboardQwerty,
};


function KeyboardInput({onLetterKeyDown}) {
    const onKeyDown = evt=> {
        const keyName = evt.key;
        const keyNameUpper = keyName.toUpperCase();
        if (ALL_LETTERS.indexOf(keyNameUpper) > -1) {
            if (onLetterKeyDown) {
                onLetterKeyDown(keyNameUpper);
            }
        }
    };

    useEffect(()=> {
        document.addEventListener("keydown", onKeyDown);
        return () => document.removeEventListener("keydown", onKeyDown);

    });

    return null;
}
